



































































































import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapMutations, mapState } from 'vuex';
import axios from 'axios';
import RepositoryFactory from '@/services/RepositoryFactory';
import IShipmentProviderConfig from '@/services/Repositories/shipmentProviderConfigs/types';
import BasePopup from '@/components/ui/BasePopup.vue';
import DPDErrorHandler from '@/services/DpdRepositories/DPDErrorHandler';
import dpdRepositories from '@/services/DpdRepositories/getRepositories/getRepositories';

const factory = new RepositoryFactory();

export default Vue.extend({
  name: 'AddShipmentProvider',
  components: {
    ValidationProvider,
    ValidationObserver,
    BasePopup,
  },
  data() {
    return {
      ShipmentProvider: {
        name: '',
        interfaceType: 'rest',
        provider: '',
        envType: '',
        dpdRestConfig: {
          userCredentialsCloudUserId: '',
          userCredentialsToken: '',
          boxShippingShipService: '',
        },
      },
      boxShippingShipServices: ['Classic_Predict', 'Express_18'],
      providers: [],
      environment: [],
      popupIsOpen: false,
    };
  },
  computed: {
    ...mapState('globals', ['addProviderError']),
    shipmentProviderConfig(): IShipmentProviderConfig {
      return factory.get('shipmentConfig') as IShipmentProviderConfig;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_PAGE', 'SET_PREVIOUS_PAGE', 'SET_ADD_PROVIDER_ERROR']),
    async addProvider() {
      const environmentType = this.ShipmentProvider.envType === 'live';

      const NewShipmentProvider = this.ShipmentProvider;

      // eslint-disable-next-line max-len
      const zipCodeData = await dpdRepositories.getZipCode(this.ShipmentProvider.dpdRestConfig, environmentType);

      if (zipCodeData.ErrorDataList) {
        this.ShipmentProvider.dpdRestConfig = {
          userCredentialsCloudUserId: '',
          userCredentialsToken: '',
          boxShippingShipService: '',
        };

        const [first] = zipCodeData.ErrorDataList;
        DPDErrorHandler.handler(first);
        return;
      }

      const data = await this.shipmentProviderConfig.create(NewShipmentProvider);
      if (data.status === 201) {
        this.popupIsOpen = true;
      }
    },
    async getEnums() {
      const apiURL = process.env.VUE_APP_BASE_URL;
      const data = await axios.get(`${apiURL}docs.json`);
      this.environment = data.data.components.schemas[
        'ShipmentProviderConfig-shipment_provider_config_context.read'
      ].properties.envType.enum;
      this.providers = data.data.components.schemas[
        'ShipmentProviderConfig-shipment_provider_config_context.read'
      ].properties.provider.enum;
    },
  },
  created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      title: 'shipment.add-provider',
      icons: { icon: icons.back, action: actions.goBack },
    });
    this.getEnums();
    this.SET_PAGE('');
    this.SET_PREVIOUS_PAGE({ name: 'ShippingProvider' });
  },
});
