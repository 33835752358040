import Axios, { AxiosRequestConfig } from 'axios';

const axiosConfig: AxiosRequestConfig = {};

const baseURL: string = process.env.VUE_APP_DPD_URL;

axiosConfig.baseURL = baseURL;

const axiosInstace = Axios.create(axiosConfig);

export default axiosInstace;
