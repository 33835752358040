var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-2"},[_c('p',[_vm._v(_vm._s(_vm.$t('shipment.add-provider')))]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addProvider)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('shipment.name'),"error-messages":errors,"success":valid,"outlined":""},model:{value:(_vm.ShipmentProvider.name),callback:function ($$v) {_vm.$set(_vm.ShipmentProvider, "name", $$v)},expression:"ShipmentProvider.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.providers,"label":_vm.$t('shipment.providers'),"error-messages":errors,"success":valid,"outlined":""},model:{value:(_vm.ShipmentProvider.provider),callback:function ($$v) {_vm.$set(_vm.ShipmentProvider, "provider", $$v)},expression:"ShipmentProvider.provider"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.boxShippingShipServices,"label":_vm.$t('shipment.service'),"error-messages":errors,"success":valid,"outlined":""},model:{value:(_vm.ShipmentProvider.dpdRestConfig.boxShippingShipService),callback:function ($$v) {_vm.$set(_vm.ShipmentProvider.dpdRestConfig, "boxShippingShipService", $$v)},expression:"ShipmentProvider.dpdRestConfig.boxShippingShipService"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.environment,"label":_vm.$t('shipment.env-type'),"error-messages":errors,"success":valid,"outlined":""},model:{value:(_vm.ShipmentProvider.envType),callback:function ($$v) {_vm.$set(_vm.ShipmentProvider, "envType", $$v)},expression:"ShipmentProvider.envType"}})]}}],null,true)})],1)],1),_c('div',[_c('p',[_vm._v("Rest config")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('shipment.cloud-user-id'),"error-messages":errors,"success":valid,"outlined":""},model:{value:(_vm.ShipmentProvider.dpdRestConfig.userCredentialsCloudUserId),callback:function ($$v) {_vm.$set(_vm.ShipmentProvider.dpdRestConfig, "userCredentialsCloudUserId", $$v)},expression:"ShipmentProvider.dpdRestConfig.userCredentialsCloudUserId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('shipment.token'),"error-messages":errors,"success":valid,"outlined":""},model:{value:(_vm.ShipmentProvider.dpdRestConfig.userCredentialsToken),callback:function ($$v) {_vm.$set(_vm.ShipmentProvider.dpdRestConfig, "userCredentialsToken", $$v)},expression:"ShipmentProvider.dpdRestConfig.userCredentialsToken"}})]}}],null,true)})],1)],1)],1),_c('v-btn',{staticClass:"primary",attrs:{"type":"submit","disabled":invalid}},[_vm._v("Submit")])],1)]}}])}),_c('BasePopup',{attrs:{"popupIsOpen":_vm.popupIsOpen},scopedSlots:_vm._u([{key:"popup-body",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shipment.added-provider'))+" ")]},proxy:true},{key:"popup-actions",fn:function(){return [_c('v-btn',{attrs:{"to":"/configuration/shipping-provider","text":"","color":"success"}},[_vm._v(" OK ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }