/* eslint-disable quote-props */
import axios from '@/services/DpdRepositories/Repository';

interface IConfig {
  userCredentialsCloudUserId: string;
  userCredentialsToken: string;
}

export { IConfig };

const getRepositories = {
  async getZipCode(config: IConfig, prodEnv: boolean): Promise<any> {
    const partnerCredentialsName = prodEnv
      ? process.env.VUE_APP_DPD_PROD_CREDENTIALS_NAME
      : process.env.VUE_APP_DPD_STAGING_CREDENTIALS_NAME;
    const partnerCredentialsToken = prodEnv
      ? process.env.VUE_APP_DPD_PROD_CREDENTIALS_TOKEN
      : process.env.VUE_APP_DPD_STAGING_CREDENTIALS_TOKEN;
    const baseUrl = prodEnv ? process.env.VUE_APP_DPD_PROD : process.env.VUE_APP_DPD_STAGING;

    axios.defaults.headers = {
      'Version': process.env.VUE_APP_DPD_VERSION,
      'UserCredentials-cloudUserID': config.userCredentialsCloudUserId,
      'UserCredentials-Token': config.userCredentialsToken,
      'Language': 'de_DE',
      'PartnerCredentials-Name': partnerCredentialsName,
      'PartnerCredentials-Token': partnerCredentialsToken,
    };

    axios.defaults.baseURL = baseUrl;

    const { data } = await axios.get('zipCodeRules');

    return data;
  },
};

export default getRepositories;
