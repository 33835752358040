import store from '@/store';
import { EnumsActions } from '@/store/modules/globals/types';

const ErrorHandler = {
  handler(error: any): void {
    if (error) {
      const { ErrorID } = error;
      const message = this.createErrorMessage(ErrorID);

      store.commit('globals/SET_ALERT_TITLE', 'errors.title');
      store.commit('globals/SET_ALERT_TEXT', message);
      store.commit('globals/SET_ALERT_DIALOG');
      store.commit('globals/SET_ALERT_TWO_BUTTONS', false);
      store.commit('globals/SET_ADD_PROVIDER_ERROR', true);
      store.commit('globals/SET_ACTION', EnumsActions.closeDialog);
    }
  },

  createErrorMessage(status: number): string {
    switch (status) {
      case 2021:
        return `Wrong DPD credentials. Please check your:
         - Cloud ID
         - Token
         - Environment type (sandbox/live)
         and try again.`;
      default:
        return `${status}`;
    }
  },
};

export default ErrorHandler;
